import React, { useCallback, useState } from 'react';

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

const initialValues = {
  subject: '',
  email: '',
  message: '',
  termsAccepted: false,
};

const useForm = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [values, setValues] = useState(initialValues);

  const resetForm = useCallback(() => {
    setValues(initialValues);
  }, []);

  const handleChange = useCallback(
    e => {
      setValues({ ...values, [e.target.name]: e.target.value });
    },
    [values]
  );

  const handleCheck = useCallback(
    e => {
      setValues({ ...values, [e.target.name]: !!e.target.checked });
    },
    [values]
  );

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      setIsSubmitting(true);

      const form = e.target;

      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': form.getAttribute('name'),
          ...values,
        }),
      })
        .then(() => {
          resetForm();
          setIsSuccess(true);
          setIsSubmitting(false);

          setTimeout(() => {
            setIsSuccess(false);
          }, 7000);
        })
        .catch(error => {
          setIsSubmitting(false);
          alert(error);
        });
    },
    [values]
  );

  return {
    values,
    isSuccess,
    isSubmitting,
    handleSubmit,
    handleCheck,
    handleChange,
  };
};

export default useForm;
