import { useCallback, useEffect, useRef } from 'react';

function usePortal() {
  const rootElemRef = useRef(null);

  useEffect(() => {
    const portalRoot = document.querySelector(`#portal`);
    portalRoot.appendChild(rootElemRef.current);
  }, []);

  const getRootElem = useCallback(() => {
    if (!rootElemRef.current) {
      rootElemRef.current = typeof document !== `undefined` ? document.createElement('div') : null;
    }

    return rootElemRef.current;
  }, []);

  return getRootElem();
}

export default usePortal;
