import { StaticQuery, graphql } from 'gatsby';

import AddressIcon from '../../../assets/people-carry.inline.svg';
import ArrowRightIcon from '../../../assets/arrow-right-link.inline.svg';
import EmailIcon from '../../../assets/users.inline.svg';
import PhoneIcon from '../../../assets/hockey-puck.inline.svg';
import React from 'react';
import { Row } from 'react-flexbox-grid';
import styled from 'styled-components';

const Container = styled.div`
  display: block;
  align-items: flex-start;
`;

const Text1 = styled.div`
  font-size: 1.4rem;
  line-height: 1.4rem;
  font-weight: bold;
  font-family: ${props => props.theme.fonts.sansSerif};
  flex-basis: 100%;
  color: ${props => props.theme.colors.kelp};
  margin-bottom: 0.2rem;
`;

const Text2 = styled(Text1)`
  font-weight: normal;
  flex-basis: 100%;
  line-height: 1.3;
`;

const TextBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 0.2rem;
`;

const IconContainer = styled.div`
  flex-basis: 3rem;
  width: 3rem;
  flex-shrink: 0;
`;

const Item = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

const Link = styled.a`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  color: ${props => props.theme.colors.meteor};
  font-size: 1.4rem;
  line-height: 1.4rem;
  font-family: Barlow, sans-serif;
  font-weight: normal;
`;

const StyledArrowRightIcon = styled(ArrowRightIcon)`
  margin-left: 0.2rem;
  margin-top: 0.2rem;
`;

const Details = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              company
              addressLine1
              addressLine2
              email
              phone
            }
          }
        }
      `}
      render={data => {
        const { company, addressLine1, addressLine2, email, phone } = data.site.siteMetadata;

        return (
          <Container>
            <Item>
              <IconContainer>
                <AddressIcon />
              </IconContainer>
              <TextBlock>
                <Text1>{company}</Text1>
                <Text2>
                  {addressLine1}
                  <br />
                  {addressLine2}
                </Text2>
              </TextBlock>
            </Item>
            <Item>
              <IconContainer>
                <EmailIcon />
              </IconContainer>
              <TextBlock>
                <Text1>{email}</Text1>
              </TextBlock>
            </Item>
            <Item>
              <IconContainer>
                <PhoneIcon />
              </IconContainer>
              <TextBlock>
                <Text1>{phone}</Text1>
              </TextBlock>
            </Item>
            <Text2
              css={`
                margin-bottom: 1rem;
              `}
            >
              Optimal number of players for every table is 8 people.
            </Text2>
            <Text2>
              It is possible to organise game for bigger audience. It would just requaierd more Facilitators.
            </Text2>
            <Link target="_blank" rel="noopener noreferrer" href={`mailto:${email}`}>
              <div
                css={`
                  display: inline-block;
                `}
              >
                Contact us for adjustments
              </div>
              <StyledArrowRightIcon />
            </Link>
          </Container>
        );
      }}
    />
  );
};

export default Details;
