import { useLayoutEffect } from 'react';

const useLockBodyScroll = active => {
  useLayoutEffect(() => {
    if (active) {
      document.body.classList.add('locked');
    } else {
      document.body.classList.remove('locked');
    }

    return () => {
      document.body.classList.remove('locked');
    };
  }, [active]);
};

export default useLockBodyScroll;
