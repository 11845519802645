import * as Base from '../../../components/base';
import * as hooks from '../../../hooks';

import { Col, Row } from 'react-flexbox-grid';

import React from 'react';
import styled from 'styled-components';

const Button = styled(Base.Button)`
  font-size: 1.6rem;
  padding: 1rem 3rem;
  width: 100%;
  max-width: 10rem;
`;

const SuccessMessage = styled.div`
  display: inline-block;
  text-align: left;
  width: 100%;
  padding: 1rem 2rem;
  color: ${props => props.theme.colors.schist};
  font-weight: bold;
  background-color: ${props => props.theme.colors.heavyMetal};
  font-size: 1.8rem;
  margin-top: 2rem;
`;

const Form = () => {
  const { values, isSuccess, isSubmitting, handleSubmit, handleCheck, handleChange } = hooks.useForm();

  return (
    <form
      name="contact"
      method="post"
      action="/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
    >
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type="hidden" name="form-name" value="contact" />
      <p hidden>
        <Base.Label>
          Don’t fill this out: <input name="bot-field" onChange={handleChange} />
        </Base.Label>
      </p>
      <Row>
        <Base.Label>
          <Base.Input
            placeholder="What's your e-mail address?"
            value={values.email}
            type="email"
            name="email"
            onChange={handleChange}
            required
          />
        </Base.Label>
      </Row>
      <Row>
        <Base.Label>
          <Base.Input
            placeholder="Subject"
            value={values.subject}
            type="text"
            name="subject"
            onChange={handleChange}
            required
          />
        </Base.Label>
      </Row>
      <Row
        css={`
          margin-bottom: 2rem;
        `}
      >
        <Base.Label>
          <Base.TextArea
            placeholder="Write your message here..."
            value={values.message}
            name="message"
            onChange={handleChange}
            rows={8}
            required
          />
        </Base.Label>
      </Row>
      <Row center="xs" between="xs">
        <div
          css={`
            flex-basis: calc(100% - 10rem - 2rem);
          `}
        >
          <Base.Checkbox checked={values.termsAccepted} name="termsAccepted" onChange={handleCheck} required>
            By checking this box you accept our Terms of use
          </Base.Checkbox>
        </div>
        <div css={`flex-basis:10rem)`}>
          <Button type="submit" disabled={isSubmitting}>
            Send
          </Button>
        </div>
      </Row>
      {isSuccess ? (
        <Row>
          <SuccessMessage>Thank you for contacting us! We will reach out to you very soon!</SuccessMessage>
        </Row>
      ) : null}
    </form>
  );
};

export default Form;
