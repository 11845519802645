import { Col, Row } from 'react-flexbox-grid';
import { StaticQuery, graphql } from 'gatsby';

import Details from './Details';
import Form from './Form';
import HangoutLink from './HangoutLink';
import Img from 'gatsby-image';
import React from 'react';
import Separator from '../../../assets/separator.inline.svg';
import styled from 'styled-components';

const StyledImg = styled(Img)`
  display: block;
  height: auto;
  max-width: 30rem;
  margin: 0;

  @media ${props => props.theme.breakpoints.xs} {
    max-width: 35rem;
  }

  @media ${props => props.theme.breakpoints.md} {
    max-width: 37rem;
    margin: 0 auto;
  }
`;

const Contact = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          trees: file(relativePath: { eq: "trees.png" }) {
            childImageSharp {
              fluid(maxWidth: 370) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <div>
          <Row
            bottom="md"
            css={`
              margin-bottom: 4rem;
            `}
          >
            <Col xs={12} md={6}>
              <HangoutLink />
            </Col>
            <Col xs={12} md={6}>
              <StyledImg fluid={data.trees.childImageSharp.fluid} />
            </Col>
          </Row>
          <Row
            css={`
              margin-bottom: 5rem;
            `}
          >
            <Separator />
          </Row>
          <div
            css={`
              display: flex;
              flex-wrap: nowrap;
            `}
          >
            <div
              css={`
                @media ${props => props.theme.breakpoints.lg} {
                  margin-right: 4.5rem;
                  flex-basis: auto;
                  flex-shrink: 2;
                }
              `}
            >
              <Form />
            </div>
            <div
              css={`
                display: none;

                @media ${props => props.theme.breakpoints.lg} {
                  display: block;
                  flex-basis: 20rem;
                  width: 20rem;
                  flex-shrink: 0;
                }
              `}
            >
              <Details />
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default Contact;
